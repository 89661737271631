@import "variables";

.thumbnail {
  height: auto;
  max-height: 80px;
  max-width: 80px;
  width: 100%;
}

.product {
  &::after {
    border-bottom: 1px solid $gray-200;
    content: " ";
    display: block;
    height: 1px;
    margin: 16px -16px;
  }
}

.discountLabel {
  font-size: 1rem;
}

.discountPrice {
  color: var(--theme-color-1);
  font-size: 1rem;
  font-weight: 600;
}
